var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.menu),function(item,idx){return _c('router-link',{key:item.id,attrs:{"to":_vm.getLink(item.link)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate,"mouseover":function($event){return _vm.setHovered(idx,true)},"mouseleave":function($event){return _vm.setHovered(idx,false)}}},[_c('div',{staticClass:"menu",style:(_vm.btnStyles(idx,isActive))},[_vm._v(" "+_vm._s(_vm.translate(item.i18n).label)+" ")])])]}}],null,true)})}),1),_c('v-menu',{staticClass:"hidden-md-and-up",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-md-and-up",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("menu")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":_vm.getLink(item.link)}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.translate(item.i18n).label))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }