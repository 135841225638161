<template>
  <div id="app">
    <app-layout></app-layout>
    <Loading/>
  </div>
</template>

<script>
import AppLayout from './views/layouts/AppLayout'
import Loading from './components/Loading'

export default {
  name: 'App',

  components: {
    AppLayout,
    Loading
  },

  data: () => ({
    //
  }),
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Home',
      // all titles will be injected into this template
      titleTemplate: this.$root.settings.title + ' | %s',
      meta: [
        {name: 'description', content: this.$root.settings.meta_description},
        {name: 'keywords', content: this.$root.settings.meta_keywords}
        //  { charset: 'utf-8' },
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ],
      link: [
        // { rel: 'stylesheet', href: '/css/index.css' },
        //{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons' }
        // { rel: 'favicon', href: 'favicon.ico' }
      ],
      script: [
        //{ src: './static/js/pdfobject.min.js' } // 'https://cdnjs.cloudflare.com/ajax/libs/pdfobject/2.1.0/pdfobject.min.js'
      ]
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Noto+Serif:wght@700&family=Source+Serif+Pro:wght@700&display=swap");
</style>
