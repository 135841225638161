<template>
  <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="color"
      v-model="show"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'feedback',
  data() {
    return {
      show: false,
      message: '',
      color: 'red'
    }
  },
  computed: {
    ...mapGetters({
      feedbackMessage: 'feedback/message',
      feedbackColor: 'feedback/color'
    })
  },
  methods: {
    ...mapActions({
      clearFeedback: 'feedback/clearFeedback'
    })
  },
  watch: {
    $route() {
      this.clearFeedback()
    },
    feedbackMessage(msg) {
      if (msg) {
        this.show = true
        this.message = msg
        this.color = this.feedbackColor
        this.clearFeedback() // this.$store.commit('feedback/setFeedback', '')
      }
    }
  }
}
</script>

<style scoped>
.v-snack, .v-btn {
  color: #000000;
}
</style>
