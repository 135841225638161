<template>
  <!--     <h4>{{ projects }}</h4>-->
  <div>
    <div v-for="project in projects" :key="project.id">
      <ArticleFilesHome
          :images="project.files"
          :imgpreset="'system-large-contain'"
          :link="getHoverLink(project.hoverlink)"
          :hovertext="project.hovertext"
          :usedesctext="project.use_desc_hovertext"
      >
<!--                                    <v-card-title class="justify-center">{{ translate(project.i18n).title }}</v-card-title>-->
        <!--                            <v-card-subtitle class="justify-center">{{ translate(project.i18n).subtitle }}</v-card-subtitle>-->
      </ArticleFilesHome>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import ArticleFilesHome from "@/components/ArticleFilesHome"
import Downloads from "@/components/Downloads"
import Links from "@/components/Links"
import * as api from "@/directus/api";

export default {
  components: {
    ArticleFilesHome,
    Downloads,
    Links
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
  },
  methods: {
    translate(item) {
      return api.geti18n(item)
    },

    getHoverLink(link) {
      if (link == '#')
        return '#'
      if (link) {
        if (link.startsWith('http'))
          return link
        else
          return '/' + this.$i18n.locale + link
      }
      return null
    },

    isHoverLink(link, hovertext) {
      if (link === '#')
        return hovertext !== ''
      else
        return true
    },

  }
}

</script>

<style scoped>


a[href*='#'] {
  cursor: default;
}

img {
  width: 100%;
}


</style>

