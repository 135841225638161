<template>
  <v-container class="white">
    <!--     <div>{{ project }}</div>-->
    <v-row v-if="project.i18n">
      <v-col cols="12">
        <h1 class="text-left">{{ translate(project.i18n).title }}</h1>
        <p class="text-left subtitle">{{ translate(project.i18n).subtitle }}</p>
        <p class="body-1 text-left mt-3" v-html="translate(project.i18n).text"></p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" sm="4" v-for="(item,idx) in project.files" :key="item.id">
        <v-card style="cursor:pointer" outlined>
          <v-img :aspect-ratio="1" max-height="500" :src="imagePreset(item)" @click="index = idx"/>
        </v-card>
      </v-col>
    </v-row>
    <CoolLightBox
        :items="lightboxItems"
        :index="index"
        @close="index = null">
    </CoolLightBox>
    <v-row>
      <v-col cols="12">
        <Links label="Links" :links="project.links"/>
      </v-col>
      <v-col cols="12">
        <Downloads :path="getDownloadLink()" label="Downloads"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Downloads from "@/components/Downloads"
import Links from "@/components/Links"
import {getArticleDetail, getFilePresetUrl, geti18n} from "@/directus/api";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: "Project",
  components: {
    Downloads,
    Links,
    CoolLightBox,
  },
  data: () => (
      {
        project: {},
        lightboxItems: [],
        index: null
      }
  ),
  methods: {
    translate(items) {
      return geti18n(items)
    },

    imagePreset(item) {
      return getFilePresetUrl(item, 'system-large-contain')
    },

    async initProject() {
      // getArticleDetail(this.$route.params.id).then(data => this.project = data)
      this.project = await getArticleDetail(this.$route.params.id)
      this.project.files.sort((x, y) => x.sort - y.sort)
      this.project.files.forEach((file) => {
            this.lightboxItems.push(this.imagePreset(file))
          }
      )
    },

    getDownloadLink() {
      return '/project/' + this.$route.params.id
    },
  },
  mounted() {
    this.initProject()

  }
}
</script>

<style scoped>

</style>
