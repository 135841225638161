import * as api from "@/directus/api";
import store from '@/store'

const state = {
    articles: [],
    menu: [],
    routerpaths: [],
    languages: [],
    files: []
}
const getters = {
    articles: (state) => () => {
        //console.log('getters.articles',state.articles,link)
        return state.articles
    },
    languages: state => {
        return state.languages
    },
    menu: (state) => (position) => {
        return state.menu.filter(pos => pos.position == position)
    },
    files: state => {
        return state.files
    },
    routerpaths: state => {
        return state.routerpaths
    },
}
const actions = {
    getArticles({commit}, payload) {
        store.dispatch('loading/setLoading', true)
        return api.getArticles(payload.type, payload.link).then((data) => {
            payload.data = data
            commit('setArticles', payload)
            store.dispatch('loading/setLoading', false)
        })
    },
    getFiles({commit}, payload) {
        store.dispatch('loading/setLoading', true)
        return api.getFiles(payload.ids, payload.type).then((data) => {
            commit('setFiles', data)
        }).catch(err => {
            console.log('getFiles store error:', err)
        })
            .finally(() => {
                store.dispatch('loading/setLoading', false)
            })
    },
    getMenu({commit}) {
        store.dispatch('loading/setLoading', true)
        return api.getMenu().then((data) => {
            commit('setMenu', data)
            store.dispatch('loading/setLoading', false)
        }).catch(err => {
            console.log('getMenu store error:', err)
        })
            .finally(() => {
                store.dispatch('loading/setLoading', false)
            })
    },
    getLanguages({commit}) {
        store.dispatch('loading/setLoading', true)
        return api.getLanguages().then((data) => {
            commit('setLanguages', data)
            store.dispatch('loading/setLoading', false)
        }).catch(err => {
            console.log('getLanguages store error:', err)
        })
            .finally(() => {
                store.dispatch('loading/setLoading', false)
            })
    }
}
const mutations = {
    setArticles(state, payload) {
        state.articles[payload.link] = []
        state.articles[payload.link][payload.type] = payload.data
    },
    setFiles(state, data) {
        //console.log('setFiles',data)
        state.files = data
    },
    setLanguages(state, data) {
        // console.log('setLanguages',data)
        state.languages = data
    },
    setMenu(state, data) {
        //console.log('setMenu',data)
        state.menu = data
        state.routerpaths = []
        data.forEach((mnu) => {
            let l = mnu.link.substring(1)
            if (!state.routerpaths.includes(l)) {
                state.routerpaths.push(l)
            }
        })
        // console.log('setLinks',state.routerpaths)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
