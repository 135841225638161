import Contact from "@/views/pages/Contact"
import Articles from "@/views/pages/Articles"
import Project from "@/views/pages/Project";

const addDynamicRoutes = (routerPath, router, curLang) => {
    let {routes} = router.options;
    let routeData = routes.find(r => r.path === '/:lang');

    let config = {}
    routerPath.forEach(r => {
        config = {
            path: r,
            name: r.charAt(0).toUpperCase() + r.slice(1),
            component: r == 'contact' ? Contact : Articles
        }

        routeData.children.push(config)
    })

    routeData.children.push({
        path: 'project/:id',
        name: 'Projectdetail',
        component: Project
    })

    router.addRoutes([routeData])

    if (!router.currentRoute.name && routerPath.find(r => r === 'home')) {
        router.push({path: '/' + curLang + '/home'});
    }

    //console.log('addDynamicRoutes',router)
}

export default addDynamicRoutes
