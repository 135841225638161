<template>
  <div>
    <v-row v-if="item.i18n">
      <v-col cols="12">
        <h1 class="text-left" v-if="translate(item.i18n).title">{{ translate(item.i18n).title }}</h1>
        <p class="text-left subtitle" v-if="translate(item.i18n).subtitle">{{ translate(item.i18n).subtitle }}</p>
        <p class="body-1 text-left mt-3" v-if="translate(item.i18n).text" v-html="translate(item.i18n).text"></p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" sm="4" v-for="(item,idx) in item.files" :key="item.id">
        <v-card style="cursor:pointer" outlined>
          <v-img :aspect-ratio="1" max-height="500" :src="imagePreset(item)" @click="index = idx"/>
        </v-card>
      </v-col>
    </v-row>
    <CoolLightBox
        :items="initLightboxItems"
        :index="index"
        @close="index = null">
    </CoolLightBox>
    <v-row>
      <v-col cols="12" v-if="item.links">
        <Links label="Links" :links="item.links"/>
      </v-col>
      <v-col cols="12">
        <Downloads :path="path" label="Downloads"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Articlefiles from "@/components/ArticleFiles"
import Downloads from "@/components/Downloads"
import Links from "@/components/Links"
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import * as api from "@/directus/api"
import {getArticleDetail, getFilePresetUrl} from "@/directus/api"

export default {
  components: {
    Articlefiles,
    Downloads,
    Links,
    CoolLightBox
  },
  props: {
    path: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },
  data: () => (
      {
        index: null
      }
  ),
  computed: {
    initLightboxItems() {
      let l = []
      if(this.item.files)
         this.item.files.forEach((file) => {
            l.push(this.imagePreset(file))
          }
      )
      return l
    },
  },
  methods: {
    imagePreset(item) {
      return getFilePresetUrl(item, 'system-large-contain')
    },

    translate(item) {
      return api.geti18n(item)
    },


  }
}

</script>

<style scoped>
div.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 100%;*/
  /*width: 100%;*/
  opacity: 0;
  transition: .5s ease;
  background-color: #f2f2f5;
}

.div-hover:hover .overlay {
  opacity: 0.70;
}

.text {
  color: black;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Source Serif Pro';
  font-weight: bold;
}

a[href*='#'] {
  cursor: default;
}

img {
  width: 100%;
}


</style>

