<template>
  <div class="divflex">
    <div v-for="article in texts" :key="article.id">
      <ArticleText :path="getLink()" :item="article" v-if="article.display=='vertical'"/>
      <ArticleText3Cols :path="getLink()" :item="article" v-if="article.display=='threecols'"/>
    </div>
    <ArticleProject :projects="projects" v-if="projects.length>0"/>
    <ArticleHome :projects="home"  v-if="home.length>0"/>
  </div>
</template>

<script>
/* eslint-disable */
import ArticleText from "@/components/ArticleText"
import ArticleText3Cols from "@/components/ArticleText3Cols"
import ArticleProject from "@/components/ArticleProject"
import ArticleHome from "@/components/ArticleHome"
import * as api from "@/directus/api";

export default {
  components: {
    ArticleText,
    ArticleText3Cols,
    ArticleProject,
    ArticleHome
  },
  data() {
    return {
      componentKey: 0,
      texts: [],
      projects: [],
      home: [],
      link: ''
    };
  },
  methods: {
    getLink() {
      //console.log('getLink()', this.$route.path);
      let p = this.$route.path;
      p = '/' + p.split('/').pop()
      return p
    },
    initArticles() {
      let self = this;
      api.getArticles(this.getLink())
          .then((result) => {
            result.forEach(a => {
              if(a.files  )
                a.files.sort((x, y) => x.sort - y.sort)
              if (a.type == 'project') {
                self.projects.push(a)
              }
              if (a.type == 'home') {
                self.home.push(a)
              } else if (a.type == 'text') {
                self.texts.push(a)
              }
            })
          })
    }
  },
  mounted() {
    this.initArticles()
    //console.log(this.$vuetify.breakpoint)
  }
}

</script>

<style>

.divflex {
  width: 100%;
}
</style>
