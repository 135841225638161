<template>
  <v-card outlined v-if="images.length>0">
    <!--    <v-card outlined>-->
    <div v-for="(img,index) in images" :key="img.id">
      <v-img :aspect-ratio="1" max-height="500" :class="getClass(index)" :src="imagePreset(img)"/>
    </div>
    <slot></slot>
  </v-card>
</template>

<script>
import * as api from "@/directus/api";

export default {
  name: "artikel",
  props: {
    images: {
      type: Array,
      required: true
    },
    onlyShowFirst: {
      type: Boolean,
      default: true
    },
    imgpreset: {
      type: String,
      default: 'system-medium-contain'
    }
  },
  methods: {
    imagePreset(item) {
      return api.getFilePresetUrl(item, this.imgpreset)
    },
    getClass(index) {
      if (this.onlyShowFirst) {
        return index > 0 ? 'd-none' : ''
      }
      return '';
    },
  }
}
</script>

<style scoped>

</style>
