<template>
  <div class="menu">
    <!--         class="hidden-sm-and-down"  class="hidden-md-and-up"   <v-icon>more_vert</v-icon>-->
    <div class="hidden-sm-and-down">
      <router-link
          v-for="(item,idx) in menu" :key="item.id"
          :to="getLink(item.link)"
          v-slot="{ href, route, navigate, isActive }"
      >
        <a :href="href" @click="navigate" @mouseover="setHovered(idx,true)" @mouseleave="setHovered(idx,false)">
          <div :style="btnStyles(idx,isActive)" class="menu">
            {{ translate(item.i18n).label }}
          </div>
        </a>
      </router-link>
    </div>
    <v-menu class="hidden-md-and-up">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="hidden-md-and-up">
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="item in menu" :key="item.id" :to="getLink(item.link)">
          <v-list-item-content>
            <v-list-item-title> {{ translate(item.i18n).label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {geti18n, getFileUrl} from '../directus/api'
import {mapActions} from 'vuex'

export default {
  name: "Menu",
  computed: {
    menu() {
      return this.$store.getters['directus/menu'](this.position)
    }
  },
  props: {
    position: {
      type: String,
      default: 'top'
    },
  },
  methods: {
    ...mapActions({
      getMenu: 'directus/getMenu'
    }),

    setHovered(idx, value) {
      this.menu[idx].isHovered = value
    },
    btnStyles(idx, isActive) {
      let css = {}

      if ((isActive || this.menu[idx].isHovered) && this.menu[idx]) {
        let bgurl = this.menu[idx].background ? getFileUrl(this.menu[idx].background.id, 'system-medium-contain') : null
        if (bgurl) {
          Object.assign(css,
              {
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-image': 'url(' + bgurl + ')'
              });
        }
      }
      return css
    },

    translate: function (item) {
      return geti18n(item)
    },

    getLink: function (link) {
      return '/' + this.$i18n.locale + link
    }
  },

}
</script>

<style scoped>
.v-btn-not-rounded {
  border-radius: 0px !important;
}

.menu {
  text-transform: none;
  line-height: inherit;
  /*font-size: 14px;*/
  color: rgba(0, 0, 0, 0.87);
  float: left;
  padding: 5px 13px;
}


</style>
