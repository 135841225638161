<template>
  <div class="contact">
    <Articles/>
    <v-form class="form" ref="form">
      <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          label="Name"
          required
      ></v-text-field>
      <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-Mail"
          required
      ></v-text-field>
      <v-textarea
          v-model="message"
          :error-messages="messageErrors"
          auto-grow
          filled
          label="Message"
          rows="7"
      ></v-textarea>
      <v-btn
          class="mr-4"
          @click="submit"
      >
        Submit
      </v-btn>
    </v-form>
  </div>
</template>
<script>
/* eslint-disable */
import {validationMixin} from 'vuelidate'
import {required, maxLength, email} from 'vuelidate/lib/validators'
import Articles from "@/views/pages/Articles"
// import * as api from "@/directus/api"
import store from "@/store"
import emailjs from 'emailjs-com';

export default {
  components: {
    Articles,
  },

  mixins: [validationMixin],

  validations: {
    name: {required, maxLength: maxLength(30)},
    message: {required, maxLength: maxLength(200)},
    email: {required, email},
  },

  data: () => ({
    name: '',
    email: '',
    message: '',
  }),
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push(this.$t('maxlength', {name: 'Name', count: 30}))
      !this.$v.name.required && errors.push(this.$t('required', {item: 'Name'}))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('valid', {item: 'E-Mail'}))
      !this.$v.email.required && errors.push(this.$t('required', {item: 'E-Mail'}))
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.message.maxLength && errors.push(this.$t('maxlength', {name: 'Message', count: 200}))
      !this.$v.message.required && errors.push(this.$t('required', {item: 'Message'}))
      return errors
    },
  },
  methods: {
    // createMessage() {
    //   let message = {
    //     "to": [
    //       this.$root.settings.email
    //     ],
    //     "subject": "Request from designandcraft.at",
    //     "type": "html",
    //   }
    //   Object.assign(message,
    //       {
    //         "from": 'website@designandcraft.at',
    //         "body": "Anfrage von <b>{{name}}</b></br>E-Mail: {{email}} </br></br>{{message}}.",
    //         "data": {
    //           "name": this.name,
    //           "email": this.email,
    //           "message": this.message.replace(/([^>])\n/g, '$1<br/>'),
    //         }
    //       });
    //   return message
    // },
    submit() {
      this.strippedHtml()
      this.$v.$touch()
      let self = this
      if (!this.$v.$invalid) {
        emailjs.init('hdtphJc0wAB5GqNW4')
        emailjs.send("service_dzsia9y", "template_9r13tos", {
          from_name: this.name,
          email: this.email,
          message: this.message
        }).then(function (response) {
          self.$v.$reset();
          self.name = ''
          self.message = ''
          self.email = ''
          store.dispatch('feedback/setSuccess', self.$t('mailSent'))
          console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
          store.dispatch('feedback/setError', this.$t('mailError'))
          console.log('FAILED...', error);
        });

        // if(api.sendMail(this.createMessage())){
        //   store.dispatch('feedback/setSuccess', this.$t('mailSent'))
        //
        //   this.name = ''
        //   this.email = ''
        //   this.message = ''
        //
        //   this.$v.$reset();
        // }
        // else {
        //   store.dispatch('feedback/setError', this.$t('mailError'))
        // }
      }
    },
    strippedHtml() {
      let regex = /(<([^>]+)>)/ig;

      this.name = this.name.replace(regex, "");
      this.message = this.message.replace(regex, "");
      this.email = this.email.replace(regex, "");
    }
  },
}
</script>

<style scoped>
.form {
  width: 300px;
}

.contact {
  margin-left: auto;
  margin-right: auto;
}

</style>
