import Vue from 'vue'
import Vuex from 'vuex'
import loading from './modules/loading'
import feedback from './modules/feedback'
import directus from './modules/directus'
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        loading,
        feedback,
        directus
    },
    //plugins: [createPersistedState()],
})

