<template>
  <div class="loading" v-if="isLoading">
    <div class="container">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  name: 'loading',
  computed: {
    ...mapGetters({
      isLoading: 'loading/isLoading',
    })
  }
}
</script>

<style scoped>
div.loading {
  position: fixed;
  top: 50%;
  left: 50%;
}
</style>
