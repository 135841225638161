<template>
  <!--     <h4>{{ projects }}</h4>-->
  <v-row>
    <v-col cols="6" sm="4" v-for="project in projects" :key="project.id">
      <v-card hover elevation="0">
        <ArticleFiles
            :images="project.files"
            :imgpreset="'system-large-contain'"
        >
          <div class="div-hover" v-if="isHoverLink(project.hoverlink,project.hovertext)">
            <router-link :to="getHoverLink(project.hoverlink,project.id)">
              <div class="overlay ma-8">
                <div v-html="project.hovertext" class="text"></div>
              </div>
            </router-link>
            <!--            <a :href="getHoverLink(project.hoverlink,project.id)">  //refresh the hole main/app !!! -->
            <!--              <div class="overlay ma-8">-->
            <!--                <div v-html="project.hovertext" class="text"></div>-->
            <!--              </div>-->
            <!--            </a>-->
          </div>
          <!--                            <v-card-title class="justify-center">{{ translate(project.i18n).title }}</v-card-title>-->
          <!--                            <v-card-subtitle class="justify-center">{{ translate(project.i18n).subtitle }}</v-card-subtitle>-->
        </ArticleFiles>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import ArticleFiles from "@/components/ArticleFiles"
import Downloads from "@/components/Downloads"
import Links from "@/components/Links"
import * as api from "@/directus/api";

export default {
  components: {
    ArticleFiles,
    Downloads,
    Links
  },
  props: {
    projects: {
      type: Array,
      required: true
    },
  },
  methods: {
    translate(item) {
      return api.geti18n(item)
    },

    getHoverLink(link, articleId) {
      if (link == '#')
        return '#'
      // else if (link && link.startsWith('http'))
      //   return link
      else if (link)
        return '/' + this.$i18n.locale + link
      else
        return '/' + this.$i18n.locale + '/project/' + articleId
    },

    isHoverLink(link, hovertext) {
      if (link == '#')
        return hovertext == '' ? false : true
      else
        return true
    },

    initLinks(articles) {
      articles.forEach(article => {
        Object.assign(this.allLinks, article.links);
      })
    }
  }
}

</script>

<style scoped>
div.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 100%;*/
  /*width: 100%;*/
  opacity: 0;
  transition: .5s ease;
  background-color: #f2f2f5;
}

.div-hover:hover .overlay {
  opacity: 0.70;
}

.text {
  color: black;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Source Serif Pro';
  font-weight: bold;
}

a[href*='#'] {
  cursor: default;
}

img {
  width: 100%;
}


</style>

