<template>
  <v-app id="inspire">
    <!--  <Sidebar></Sidebar>-->
    <Toolbar></Toolbar>
    <Content></Content>
    <Feedback/>
    <Footerbar></Footerbar>
  </v-app>
</template>

<script>
import Toolbar from '@/views/layouts/Toolbar'
import Footerbar from '@/views/layouts/Footerbar'
import Content from '@/views/layouts/Content'
import Feedback from '@/components/Feedback'
//import Sidebar from '@/views/layouts/Sidebar'

export default {
  name: 'AppLayout',
  components: {
    Toolbar,
    Footerbar,
    Content,
    Feedback
    //   Sidebar
  }
}
</script>

<style scoped>

</style>
