/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "../plugins/i18n"
import Project from '@/views/pages/Project'
import Articles from "@/views/pages/Articles";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: `/${i18n.locale}`
    },
    {
        path: '/:lang',
        beforeEnter: (to, from, next) => { // <------------
            const language = to.params.lang
            if (!language) {
                next({
                    path: "/" + i18n.locale,
                })
            } else {
                i18n.locale = language
                next()
            }
        },
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            // { //funktioniert nicht
            //     path: '',
            //     component: Articles
            // },
            //see addroutes.js and main.js
            // mounted () {
            //     this.initSettings()
            // }
        ]
    }
]


const router = new VueRouter({
    routes,
    mode: 'history'
})


export default router

