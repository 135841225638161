<template>
  <div class="text-left">
<!--         <h4>{{ links }}</h4>-->
    <p v-if="links">{{ label }}</p>
    <ul v-for="link in links" :key="link.url">
      <li><a :href="link.url" target="_blank">{{ link.label }}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['links', 'label'],
}
</script>

<style scoped>
.v-application a {
  color: #000000;
}
</style>
