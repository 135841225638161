<template>
  <v-footer app class="white icon overflow-y-hidden d-inline-flex hidden-sm-and-down">
        <span style="margin-left: auto; margin-right: auto;" v-if="texts.length>0">
<!--            <h4>{{ texts }}</h4>-->
            <span v-for="(item,idx) in texts" :key="item.id">
                <span class="mx-6" v-if="idx>0">|</span>
                <span class="text--secondary text-caption">{{ item.text }}</span>
                <span v-if="item.email">
                    <span class="mx-6">|</span>
                    <EMail :email="item.email"/>
                  <!--<span class="mx-6">|</span>-->
                  <!--<a class="subtitle-1" :href="'mailto:' + item.email">{{ item.email }}</a>-->
                </span>
            </span>
        </span>
    <v-spacer></v-spacer>
    <Menu position="footer"></Menu>
    <!--        <span class="ml-2"><a class="subtitle-1" :href="'mailto:' + this.$root.settings.email">{{ this.$root.settings.email }}</a></span>-->
  </v-footer>
</template>

<script>
import EMail from "@/components/EMail"
import * as api from "@/directus/api";
import Menu from "@/components/Menu"

export default {
  name: 'Footerbar',
  components: {
    EMail,
    Menu
  },
  data: () => (
      {
        texts: []
      }
  ),
  methods: {
    async getFooter() {
      this.texts = await api.getFooter();
    }
  },
  mounted() {
    this.getFooter()
    console.log(this.$vuetify.breakpoint)
  }
}
</script>

<style scoped>
footer {

}

</style>
