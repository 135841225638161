/* eslint-disable */
import i18n from "@/plugins/i18n";
import axios from 'axios'

const API_SERVER = 'https://api.designandcraft.at' // https://directus'
const AXIOS_CONFIG = {headers: {'Content-Type': 'application/json', 'Cache-Control': 'max-age=3600'}}

const getFileUrl = (id, preset = null) => {
    return preset == null ? `${API_SERVER}/assets/${id}` : `${API_SERVER}/assets/${id}?key=${preset}`
}

const getFilePresetUrl = (item, preset = null) => {
    if (item.files_id)
        return getFileUrl(item.files_id.id, preset)

    return ''
}

const getItems = (type, params = {}) => {
    try {
        return axios.get(API_SERVER + '/items/' + type, {headers: {'Content-Type': 'application/json'}, params: params})
    } catch (error) {
        console.error(error)
    }
}


const _getFooter = async () => {
    return await getItems('footer',
        {
            filter: {status: {"_eq": 'published'}}
        });
}

const _getArticles = async (link) => {
    let r = await getItems('article',
        {
            fields: '*.*,files.*.*',
            filter: {status: {"_eq": 'published'}, menu: {link: {"_eq": link}}}
        }
    );
    return r.data.data;
}

const _getArticleDetail = async (id) => {
    let r = await getItems('article',
        {
            fields: '*.*,files.*.*',
            filter: {'id': {"_eq": id}, status: {"_eq": 'published'}}
        });
    return r.data.data;
}

const _getDownloads = async (link) => {
    //console.log('_getDownloads',link)
    let r = await getItems('downloads',
        {
            fields: '*.*.*',
            filter: {status: {"_eq": 'published'}, link:{"_eq": link}}
        });
    return r.data;
}

const _getMenu = async () => {
    let r = await getItems('menu',
        {
            fields: '*.*',
            filter: {status: {"_eq": 'published'}},
            sort: 'sort'
        });
    return r.data.data
}


const _getFiles = (ids) => {
    return getItems.getFile(ids)
}


const _getLanguages = async () => {
    let r = await getItems('languages',
        {
            filter: {status: {"_eq": 'published'}}
        })
    return r.data.data
}

const getSettings = async () => {
    let r = await getItems('settings', {
        fields: '*.*',
    });
    return r.data.data
}


const getLanguages = async () => {
    let locales = []
    const obj = await _getLanguages()

    obj.forEach(item => {
        locales.push({
            code: item.code,
            name: item.name
        })
    })

    return locales
}


const getFiles = async (ids, type) => {
    let files = await _getFiles(ids)

    if (type) {
        const found = files.data.data.thumbnails.find(function (el) {
            return el.key === type;
        });
        if (found)
            return found;
    }
    return files.data
}

const getFooter = async () => {
    let footer = await _getFooter()
    //console.log('footer',footer)
    return footer.data
}


const getArticles = async (link) => {
    let articles = await _getArticles(link)
    //console.log(articles)
    return _initTranslation(articles)
}

const getArticleDetail = async (id) => {
    let article = await _getArticleDetail(id)
    let r = _initTranslation(article)
    //console.log('getArticleDetail',r[0])
    return r.length > 0 ? r[0] : []
}

const downloadClick = async (url, name) => {
    axios.get(url, {responseType: 'blob'})
        .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = name
            link.click()
            URL.revokeObjectURL(link.href)
        }).catch(console.error)
}

const getDownloads = async (link) => {
    let d = await _getDownloads(link)
     let r = []
     d.data.forEach(item => {
         r = r.concat(item.files)
     })
    return r
    //return d.data.length > 0 ? d.data[0].files : []
}

const sendMail = async (message) => {
    let loginRes;
    try {
        loginRes = await axios.post(API_SERVER + '/auth/login/', {
            email: 'hallo@designandcraft.at',
            password: 'drangsnes',
        })
    } catch (error) {
        console.error(error)
    }

    const token = loginRes.data.access_token
    const url = API_SERVER + '/mail';
    const headers = {
        "Authorization": "bearer " + token
    };
    //console.log(token)
    const response = await axios.post(url, message, {headers});
    console.log('sendMail', response)
    const logout = await axios.post(API_SERVER + '/auth/logout', {headers});
    return response.status == 204 ? true : false
}


const getMenu = async () => {
    let menu = await _getMenu()
    menu = _initTranslation(menu)

    menu.forEach((mnu) => {
        mnu.isHovered = false
    })

    return menu
}


const _initTranslation = (itemArray) => {
    let result = []
    itemArray.forEach((item) => {
        item.i18n = {}
        if (item.translations && item.translations.length > 0) {
            let obj = {}
            item.translations.forEach((langObj) => {
                obj[langObj.languages_code] = langObj
            })
            item.i18n = obj
        }

        delete item.translations;
        result.push(item)
    })

    return result;
}

const geti18n = (item) => {
    //console.log('geti18n',i18n.locale,item)
    if (i18n.locale in item)
        return item[i18n.locale]

    return item[i18n.fallbackLocale] ? item[i18n.fallbackLocale] : ''
}


const asyncForEach = async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}


export {
    getSettings,
    getMenu,
    getArticles,
    getArticleDetail,
    getLanguages,
    geti18n,
    getFiles,
    asyncForEach,
    getFilePresetUrl,
    getDownloads,
    downloadClick,
    getFooter,
    sendMail,
    getFileUrl
}
