<template>
  <div v-if="images.length>0">
    <v-row align="center" justify="center">
      <v-col cols="6" sm="4" v-for="(img,index) in images" :key="img.id">
        <v-card outlined>
          <v-img :aspect-ratio="1" max-height="500" :class="getClass(index)" :src="imagePreset(img)"/>
          <div class="div-hover">
            <a :href="getLink()" target="_blank" v-if="getLink()">
              <div class="overlay ma-8">
                  <div v-html="getDesc(img)" class="text"></div>
              </div>
            </a>
            <div class="overlay ma-8" v-else>
              <div v-html="getDesc(img)" class="text"></div>
            </div>
          </div>
        </v-card>
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as api from "@/directus/api";

export default {
  name: "artikel",
  props: {
    usedesctext:{
      type: Boolean,
      default: false
    },
    hovertext: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    images: {
      type: Array,
      required: true
    },
    onlyShowFirst: {
      type: Boolean,
      default: true
    },
    imgpreset: {
      type: String,
      default: 'system-medium-contain'
    }
  },
  methods: {
    imagePreset(item) {
      return api.getFilePresetUrl(item, this.imgpreset)
    },
    getLink() {
      return this.link
    },
    getDesc(img) {
      if(this.usedesctext && img.files_id.description > '')
        return img.files_id.description
      else
        return this.hovertext
    },
    getClass(index) {
      if (this.onlyShowFirst) {
        return index > 0 ? '' : ''
      }
      return '';
    },
  }
}
</script>

<style scoped>
div.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 100%;*/
  /*width: 100%;*/
  opacity: 0;
  transition: .5s ease;
  background-color: #f2f2f5;
}

.div-hover:hover .overlay {
  opacity: 0.70;
}

.text {
  color: black;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Source Serif Pro';
  font-weight: bold;
}
</style>
