<template>
  <div class="text-center" v-if="languages && languages.length>1">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="v-btn-not-rounded"
               depressed
               v-bind="attrs"
               v-on="on"
        >
          {{ getCurlang($i18n.locale) }}
        </v-btn>
      </template>
      <v-list>
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <v-list-item
            v-for="(locale, index) in languages"
            :key="index"
            v-if="$i18n.locale != locale.code"
        >
          <v-list-item-title @click="selectLang(locale.code)">{{ locale.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data: () => (
      {
        curlang: null,
      }
  ),
  computed: {
    ...mapGetters({
      languages: 'directus/languages',
    })
  },
  methods: {
    initLanguages() {
      this.$store.dispatch('directus/getLanguages')
    },

    getCurlang(locale) {
      for (let idx in this.languages) {
        if (this.languages[idx].code == locale)
          return this.languages[idx].name
      }
    },

    selectLang: function (locale) {
      this.$i18n.locale = locale
      this.curlang =
          this.$router.push({ //change language in the url
            params: {lang: locale}
          }, () => {
          })
      //this.$router.go() //force refresh
    }
  },
  mounted() {
    this.initLanguages()
  }
}
</script>

<style scoped>
.v-btn-not-rounded {
  border-radius: 0px !important;
}

.v-menu__content {
  box-shadow: none;
}

.v-list-item__title {
  cursor: pointer;
}
</style>
