<template>
  <v-row class="align-center" >
    <!--    <h4>{{ item }}</h4>-->
    <v-col :cols="getCols(true)" v-if="item.files.length">
      <v-card elevation="0" v-for="img in item.files" :key="img.id">
        <div v-if="img.files_id">
          <img :src="imagePreset(img,'system-large-contain')"/>
        </div>
      </v-card>
    </v-col>
    <v-col :cols="getCols(false)">
      <h1 class="text-left" v-if="translate(item.i18n).title">{{ translate(item.i18n).title }}</h1>
      <p class="text-left subtitle" v-if="translate(item.i18n).subtitle">{{ translate(item.i18n).subtitle }}</p>
      <p class="body-1 text-left mt-3" v-if="translate(item.i18n).text" v-html="translate(item.i18n).text"></p>
    </v-col>
    <v-col cols="12" v-if="item.links">
      <Links label="Links" :links="item.links"/>
    </v-col>
    <v-col cols="12">
      <Downloads :path="path" label="Downloads"/>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import Articlefiles from "@/components/ArticleFiles"
import Downloads from "@/components/Downloads"
import Links from "@/components/Links"
import * as api from "@/directus/api";

export default {
  components: {
    Articlefiles,
    Downloads,
    Links
  },
  props: {
    path: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    getCols(isImg) {
      if (this.item && this.item.files.length > 0 && this.$vuetify.breakpoint.smAndUp)
        return isImg ? '4' : '8'
      else
        return '12'
    },

    getPath() {
      return this.path
    },
    imagePreset(item, imgpreset) {
      return api.getFilePresetUrl(item, imgpreset)
    },

    translate(item) {
      return api.geti18n(item)
    },
  }
}

</script>

<style scoped>
div.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 100%;*/
  /*width: 100%;*/
  opacity: 0;
  transition: .5s ease;
  background-color: #f2f2f5;
}

.div-hover:hover .overlay {
  opacity: 0.70;
}

.text {
  color: black;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Source Serif Pro';
  font-weight: bold;
}

a[href*='#'] {
  cursor: default;
}

img {
  width: 100%;
}


</style>

