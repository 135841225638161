const state = {
    message: null,
    code: null,
    state: null
}

const getters = {
    message(state) {
        return state.message
    },
    code(state) {
        return state.code
    },
    color(state) {
        let c = ['error', 'info', 'success']
        return c.includes(state.state) ? state.state : 'grey'
    }
}

const actions = {
    setError({commit}, msg) {
        let data = {
            message: msg,
            state: 'error'
        }
        commit('setFeedback', data)
    },
    setSuccess({commit}, msg) {
        let data = {
            message: msg,
            state: 'success'
        }
        commit('setFeedback', data)
    },
    setInfo({commit}, msg) {
        let data = {
            message: msg,
            state: 'info'
        }
        commit('setFeedback', data)
    },
    setFeedback({commit}, data) {
        commit('setFeedback', data)
    },
    clearFeedback({commit}) {
        commit('clearFeedback')
    }
}

const mutations = {
    setFeedback(state, data) {
        state.message = data.message ? data.message : null
        state.state = data.state ? data.state : null
        state.code = data.code ? data.code : null
    },
    clearFeedback(state) {
        state.message = null
        state.state = null
        state.code = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
