<template>
  <v-app-bar app flat color="white">
    <router-link to="/de/home">
      <v-img :src="logo" class="logo" max-height="45" max-width="60" v-if="$root.settings.logo"/>
    </router-link>
    <v-spacer></v-spacer>
    <Menu/>
    <LocaleSwitcher/>
  </v-app-bar>
</template>

<script>
//import LocaleSwitcher from "@/components/LocaleSwitcher"
import LocaleSwitcher from "@/components/LocaleSwitch"
import Menu from "@/components/Menu"
import * as api from "@/directus/api"

export default {
  name: 'Toolbar',
  components: {LocaleSwitcher, Menu},
  computed: {
    logo() {
      return api.getFileUrl(this.$root.settings.logo.id)
    },
  },
}
</script>

<style scoped>
.logo {
  margin-left: 12px;
}
</style>
