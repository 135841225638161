<template>
  <div class="text-left" v-if="downloads.length>0">
    <p>{{ label }}</p>
    <ul v-for="download in downloads" :key="download.directus_files_id.id">
      <li><a :href="downloadFileUrl(download.directus_files_id.id)"
             v-text="download.directus_files_id.title"
             @click.prevent="downloadClick(downloadFileUrl(download.directus_files_id.id),download.directus_files_id.filename_download)"
      />
      </li>
    </ul>
  </div>
</template>

<script>
import * as api from "@/directus/api"

export default {
  name: "Downloads",
  props: {
    path: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
  },
  //props: ['path', 'label'],
  data: () => (
      {
        downloads: []
      }
  ),
  computed: {
    //  downloads() {
    //      let self = this
    //      api.getDownloads(this.path).then((result)=>{
    //          self.downloads = result;
    //     })
    // }
  },
  methods: {
    downloadClick(url, name) {
      api.downloadClick(url, name)
    },

    downloadFileUrl(id) {
      return api.getFileUrl(id)
    },

    initDownloads() {
      let self = this;
      // console.log('initDownloads',this.path)
      api.getDownloads(this.path)
          .then((result) => {
            self.downloads = result;
          })
    }
  },
  mounted() {
    this.initDownloads()
  }
}
</script>

<style scoped>

</style>
