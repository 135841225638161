import 'babel-polyfill' // because of IE 11 / Safari 9 support of vuetify: npm install babel-polyfill
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import {credentials, client} from './directus'
import Meta from 'vue-meta'
//import VueLazyload from "vue-lazyload";
import '@/assets/styles.css'
import vuetify from './plugins/vuetify';
import '@/assets/font.sass'
import i18n from "./plugins/i18n";
import * as api from "@/directus/api";
import addDynamicRoutes from '@/router/addroutes'

Vue.config.productionTip = false

Vue.use(Meta)
//Vue.use(VueLazyload); //<img v-lazy="path-to-image">


// eslint-disable-next-line no-unused-vars
//client.login(credentials).then(result => {
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),

    data() {
        return {
            drawer: null,
            settings: {},
        }
    },
    methods: {
        async initSettings() {
            let self = this;
            api.getSettings()
                .then((result) => {
                    self.settings = result;
                    //console.log('settings',result)
                })

            await this.$store.dispatch('directus/getMenu')
            let routerPath = this.$store.getters['directus/routerpaths']
            addDynamicRoutes(routerPath, this.$router, self.$i18n.locale)
        }
    },
    mounted() {
        this.initSettings()
    }
}).$mount('#app')
//console.log(result)
//})
